import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  Select,
  Checkbox,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  VStack,
  FormControl,
  FormLabel,
  useToast,
  useColorMode,
  Input,
  Flex,
  HStack,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import axiosInstance from "../../helpers/axiosInstance";
import { fetchFund, fetchFundDetails } from "../../api/FundDocs/fileApi";
import FileDownloadButton from "./FileDownloadButton";

const MiddlePanel = ({
  resetFileStates,
  selectedFundsId,
  selectedFund,
  setSelectedFund,
  setFundsList,
  setFetchedFunds,
  crossRefCheck,
  setCrossRefCheck,
  countryCheck,
  setCountryCheck,
  selectedCountries,
  setSelectedCountries,
  marketingMaterial,
  setMarketingMaterial,
  fundProspectus,
  setFundProspectus,
  fundType,
  setFundType,
  marketingToRetail,
  setMarketingToRetail,
  insuranceProduct,
  setInsuranceProduct,
  sfdrArticle,
  setSfdrArticle,
  marketingMaterialInputRef, // Receive refs here
  fundProspectusInputRef,
  fundName,
  setFundName,
  report = [],
  setReport,
  lastStep,
  setLastStep,
  setSummary,
  fetchReportData,
}) => {
  const toast = useToast();
  const { colorMode } = useColorMode();
  const documentId = 1;

  const EU_COUNTRIES = [
    "Austria",
    "Belgium",
    "Bulgaria",
    "Croatia",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Estonia",
    "Finland",
    "France",
    "Germany",
    "Greece",
    "Hungary",
    "Ireland",
    "Italy",
    "Latvia",
    "Lithuania",
    "Luxembourg",
    "Malta",
    "Netherlands",
    "Poland",
    "Portugal",
    "Romania",
    "Slovakia",
    "Slovenia",
    "Spain",
    "Sweden",
  ];

  const handleCountrySelection = (country) => {
    const index = selectedCountries.indexOf(country);
    if (index > -1) {
      setSelectedCountries(selectedCountries.filter((c) => c !== country));
    } else {
      setSelectedCountries([...selectedCountries, country]);
    }
  };

  const handleUpload = (event, setter) => {
    setter(event.target.files[0]);
  };

  const handleSubmit = async () => {
    if (
      !selectedFund ||
      !fundName ||
      !marketingMaterial ||
      !fundType ||
      marketingToRetail === "" ||
      insuranceProduct === "" ||
      sfdrArticle === "" ||
      (crossRefCheck === "Yes" && !fundProspectus) ||
      (countryCheck === "Yes" && selectedCountries.length === 0)
    ) {
      toast({
        title: "Error",
        description: "Please fill all required fields.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return; // Prevent the submission
    }

    const formData = new FormData();
    formData.append("fundName", fundName);
    formData.append("marketingMaterial", marketingMaterial);
    formData.append("fundProspectus", fundProspectus);
    formData.append("crossRefCheck", crossRefCheck);
    formData.append("countryCheck", countryCheck);
    formData.append("selectedCountries", JSON.stringify(selectedCountries));
    formData.append("fundType", fundType);
    formData.append("marketingToRetail", marketingToRetail);
    formData.append("insuranceProduct", insuranceProduct);
    formData.append("sfdrArticle", sfdrArticle);
    formData.append("fundDocsName", selectedFund.text);

    try {
      const response = await axiosInstance.post(
        "fund_docs/upload-market-material",
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );

      // console.log("Response Status:", response.status); // Check the actual status
      // console.log("Response Data:", response.data.data); // Inspect the data

      if (response.status === 200) {
        toast({
          title: "Success",
          description: "Data submitted successfully!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        const fundId = response.data.data._id
        const fundName = response.data.data.fundName
        // setSelectedFund({ _id: fundId, fund_name: fundName });
        
        fetchReportData(fundId);
       
        // Assuming this is where things might go wrong
        try {
          if (selectedFund && selectedFund.id) {
            setFundsList((prevState) =>
              prevState.filter((fund) => fund.id !== selectedFund.id)
            );
             fetchFund(setFetchedFunds, toast);
            setSelectedFund({ _id: fundId, fund_name: fundName });
            setFundName("");
            setCrossRefCheck("No");
            setCountryCheck("No");
            setSelectedCountries([]);
            setFundType("");
            setMarketingToRetail("No");
            setInsuranceProduct("No");
            setSfdrArticle("");
            resetFileStates();
           
          }
        } catch (postError) {
          //console.error("Error after POST request success:", postError);
        }
      } else {
        toast({
          title: "Submission not successful",
          description: "Received status: " + response.status,
          status: "warning",
          duration: 3000,
          isClosable: true,
        });
        
      }
    } catch (error) {
      //console.error("Submission error:", error);
      toast({
        title: "Error",
        description: error.response?.data.message || "Failed to submit data",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const fetchFundDetails = async () => {
    // console.log("In fetchFundDetails");
    // console.log("selectedFundsId", selectedFundsId);
    if (selectedFundsId) {
      // console.log("inside selectedFundsId if condition", selectedFundsId);
      const payload = {
        str_id: selectedFundsId,
      };
      //console.log("payload", payload);
      try {
        const response = await axiosInstance.post(
          "fund_docs/fetch-fund-docs-by-id",
          payload
        );
        // console.log("fetchFundDetails response", response.data.data);
        if (response.status === 200) {
          const data = response.data.data;
          setCrossRefCheck(data.cross_ref_check);
          setCountryCheck(data.country_check);
          setSelectedCountries(data.selected_countries || []);
          setFundName(data.fund_name || "");
          setMarketingMaterial(data.marketing_material);
          setFundProspectus(data.fund_prospectus);
          setFundType(data.fund_type);
          setMarketingToRetail(data.marketing_to_retail);
          setInsuranceProduct(data.insurance_product);
          setSfdrArticle(data.sfdr_article);
          setLastStep(data?.last_step);
          setReport(data.report);
          // console.log("setReport",data.report);
          setSummary(data.summary);
          // console.log("setSummary",data.summary);
        }
      } catch (error) {
        toast({
          title: "Error fetching fund details",
          description: error.response?.data.message || "Failed to fetch data",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  useEffect(() => {
    if (selectedFundsId) {
      fetchFundDetails();
    }
  }, [selectedFundsId]); // Ensure this effect runs only when selectedFundsId changes.

  return (
    <VStack align="stretch" spacing={6}>
      <FormControl>
        <FormLabel htmlFor="fundName" mb={4}>
          Fund Name
        </FormLabel>
        <Input
          id="fundName"
          type="text"
          value={fundName}
          onChange={(e) => setFundName(e.target.value)}
          placeholder="Enter fund name"
          variant="outline"
        />
      </FormControl>

      <FormControl>
        <FormLabel mb={4}>The Marketing Material</FormLabel>
        <input
          type="file"
          ref={marketingMaterialInputRef}
          onChange={(event) => handleUpload(event, setMarketingMaterial)}
        />
      </FormControl>

      <FormControl>
        <FormLabel>Cross-Reference Check with the Fund Prospectus</FormLabel>
        <Select
          value={crossRefCheck}
          onChange={(e) => setCrossRefCheck(e.target.value)}
        >
          <option value="No">No</option>
          <option value="Yes">Yes</option>
        </Select>
      </FormControl>

      {crossRefCheck == "Yes" && (
        <FormControl>
          <FormLabel mb={4}>Fund Prospectus (Required)</FormLabel>
          <input
            type="file"
            ref={fundProspectusInputRef}
            required
            onChange={(event) => handleUpload(event, setFundProspectus)}
          />
        </FormControl>
      )}

      <FormControl>
        <FormLabel>Country-Specific Check (Yes/No)</FormLabel>
        <Select
          value={countryCheck}
          onChange={(e) => setCountryCheck(e.target.value)}
        >
          <option value="No">No</option>
          <option value="Yes">Yes</option>
        </Select>
      </FormControl>

      {countryCheck == "Yes" && (
        <FormControl>
          <FormLabel>Select Countries</FormLabel>
          <Menu closeOnSelect={false}>
            <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
              {selectedCountries.length > 0
                ? `${selectedCountries.length} selected`
                : "Select Countries"}
            </MenuButton>
            <MenuList
              maxHeight="40vh"
              overflowY="scroll"
              scrollBehavior="hidden"
              sx={{
                "&::-webkit-scrollbar": {
                  display: "none",
                },
                msOverflowStyle: "none",
                scrollbarWidth: "none",
              }}
            >
              {EU_COUNTRIES.map((country) => (
                <MenuItem key={country}>
                  <Checkbox
                    isChecked={selectedCountries.includes(country)}
                    onChange={() => handleCountrySelection(country)}
                  >
                    {country}
                  </Checkbox>
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        </FormControl>
      )}

      <FormControl>
        <FormLabel>Type of Fund</FormLabel>
        <Select
          placeholder="Select fund type"
          value={fundType}
          onChange={(e) => setFundType(e.target.value)}
        >
          <option value="UCITS">UCITS</option>
          <option value="AIF">AIF</option>
        </Select>
      </FormControl>

      <FormControl>
        <FormLabel>Marketing to Retail Investors</FormLabel>
        <Select
          value={marketingToRetail}
          onChange={(e) => setMarketingToRetail(e.target.value)}
        >
          <option value="No">No</option>
          <option value="Yes">Yes</option>
        </Select>
      </FormControl>

      <FormControl>
        <FormLabel>Insurance-Based Investment Product</FormLabel>
        <Select
          value={insuranceProduct}
          onChange={(e) => setInsuranceProduct(e.target.value)}
        >
          <option value="No">No</option>
          <option value="Yes">Yes</option>
        </Select>
      </FormControl>

      <FormControl>
        <FormLabel>SFDR Article</FormLabel>
        <Select
          placeholder="Select article"
          value={sfdrArticle}
          onChange={(e) => setSfdrArticle(e.target.value)}
        >
          <option value="Article 6">Article 6</option>
          <option value="Article 8">Article 8</option>
          <option value="Article 9">Article 9</option>
        </Select>
      </FormControl>
      {selectedFund && selectedFund.id && (
        <Button colorScheme="blue" onClick={handleSubmit}>
          Create
        </Button>
      )}
      <HStack>
        {selectedFundsId && report && report.length > 0 && (
          <FileDownloadButton
            documentId={documentId}
            selectedFundsId={selectedFundsId}
            urlPath="/fund_docs/download-marketing-material-by-id"
            buttonText="Download Marketing Material"
          />
        )}
        {selectedFundsId && report.length > 0 && (
          <FileDownloadButton
            documentId={documentId}
            selectedFundsId={selectedFundsId}
            urlPath="/fund_docs/download-fund-prospectus-by-id"
            buttonText="Download Fund Prospectus"
          />
        )}
      </HStack>
    </VStack>
  );
};

export default MiddlePanel;
